<template>
  <v-container fluid>
    <synapsa-table :data="data" :loading="loading" />
  </v-container>
</template>

<script>
import { getSynapsa } from "@/api/system/synapsa";
import synapsaTable from "@/views/system/synapsa/table";

import { Errors } from "@/utils/error";

export default {
  components: {
    synapsaTable,
  },
  data() {
    return {
      data: {},
      loading: true,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "systemSynapsa") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getSynapsa()
        .then((response) => {
          this.loading = false;
          this.data = response.data;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>
